// App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import Navbar01 from "./components/Navbar01";
import Footer from "./components/Footer";
import LandingPage01 from "./pages/LandingPage01";
import ArenaPage from "./pages/ArenaPage";
import MobileNavbar from "./components/MobileNavbar";
import Careers from "./pages/Careers";
import JobPostDetails from "./components/JobPostsDetails";
import ScrollToTop from "./components/SrollToTop";
import Contact from "./components/Contact";
import CraftsmanshipPage from './pages/CraftsmanshipPage'


function App() {

  

  return (
    <Router>
      <div className="relative z-10">
        <Navbar01 />
        <MobileNavbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LandingPage01 />} />
          <Route path="/arena" element={<ArenaPage />} />
          <Route path="/craftsmanship" element={<CraftsmanshipPage />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/more-details" element={<JobPostDetails />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
