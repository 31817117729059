import React, { useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function HeroSection() {

  useEffect(() => {
    gsap.fromTo(".title", 
      { 
        opacity: 0,
        rotation: -10,  // Slight rotation from the left
        transformOrigin: "left center"  // Anchor the rotation from the left side
      }, 
      { 
        duration: 1, 
        opacity: 1, 
        rotation: 0,  // Rotate back to normal
        ease: "bounce.out",  // Add bounce effect for a fun look
        stagger: 0.1  // Stagger if there are multiple `.title` elements
      }
    );

    gsap.fromTo(".desc", 
      { 
        opacity: 0,
        scale: 0.5,      // Start from smaller size
        y: 100,          // Start slightly below the original position
        rotation: -15,   // A larger initial rotation for more dramatic effect
        transformOrigin: "center bottom",  // Rotate from the bottom
      }, 
      { 
        delay: 1.5,
        duration: 1, 
        opacity: 1, 
        scale: 1,        // Zoom in to full size
        y: 0,            // Move back to the original position
        rotation: 0,     // Rotate back to normal
        ease: "elastic.out(1, 0.5)",  // Elastic effect for smooth bouncing
        stagger: 0.2     // Slightly larger stagger for dramatic entrance
      }
    );
    gsap.fromTo("button", 
      { 
        opacity: 0,
        scale: 0.5,      // Start from smaller size
        y: 100,          // Start slightly below the original position
        rotation: -15,   // A larger initial rotation for more dramatic effect
        transformOrigin: "center bottom",  // Rotate from the bottom
      }, 
      { 
        duration: 1.5, 
        opacity: 1, 
        scale: 1,        // Zoom in to full size
        y: 0,            // Move back to the original position
        rotation: 0,     // Rotate back to normal
        ease: "elastic.out(1, 0.5)",  // Elastic effect for smooth bouncing
        stagger: 0.2     // Slightly larger stagger for dramatic entrance
      }
    );

    const tl = gsap.timeline({
      defaults: {
        duration: 1.5,      // Default duration for all animations
        ease: "power2.out"  // Default easing
      }
    });
    
    tl.fromTo(
        "#img1", // Target the first image
        {
          y: -100,            // Start 100px above
          skewX: 180,
          skewY: 180,          // Initial skew along Y-axis
          opacity: 0          // Start with zero opacity (optional)
        },
        {
          y: 0,
          duration: 0.7,               // Move down
          skewX: 0,
          skewY: 0,           // Remove skew
          opacity: 1          // Fade in
        }
      )
      .fromTo(
        "#img2", // Target the second image
        {
          y: -100,            // Same initial state
          skewX: -90,
          skewY: -90,
          opacity: 0
        },
        {
          y: 0,
          duration: 0.7,                  // Move down
          skewX: 0,
          skewY: 0,
          opacity: 1
        }
      )
      .fromTo(
        "#img3", // Target the third image
        {
          y: -200,
          duration: 1,   
          skewX: 260,
          skewY: 260,
          opacity: 0
        },
        {
          y: 0,
          skewX: 0,
          skewY: 0,
          opacity: 1
        }
      );


  }, []);


  return (
    <div className="flex flex-col justify-center items-center bg-gradient-to-b from-[#333436] via-[#28292a] to-[#272829]  w-[100%] h-auto">
      <div className="hidden lg:flex flex-row justify-between p-4 w-full h-screen">
        <div className="flex flex-col justify-center items-start w-auto h-auto m-6">
          <h1 className="text-white mx-[10px] font-varela title" style={{ fontSize: '50px'}}>
          Techmertia Pvt Ltd
          </h1>
          <h6 className="text-white text-2xl mx-[10px] max-w-[300px] font-varela desc">
            Immersive AR/VR experiences redefining reality through innovative
            technology.
          </h6>
          <a href = "/arena" className="flex justify-center items-center w-[200px] font-varela h-[60px] text-lg font-bold m-[10px] text-[#352F44] bg-[#FAF0E6] rounded-xl p-2">
            See Our Products
          </a>
        </div>
        <div className="relative flex flex-row justify-center items-center space-x-4 w-[50%] h-full">
          <div id = "img1" className="relative w-[300px] h-[90%]  rounded-[16px] z-0 opacity-80 overflow-hidden">
            <img
              src="/images/beauty-digital-art-through-immersive-experiences (4).jpg"
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div id = "img2" className="relative w-[300px] h-[90%]  rounded-[16px] opacity-80 overflow-hidden">
            <img
              src="/images/man-wearing-vr-glasses-gaming(1).jpg"
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div id = "img3" className="absolute inset-0 left-[calc(50%-150px)] top-0 w-[300px] h-[90%]  rounded-[16px] overflow-hidden z-10">
            <img
              src="/images/beauty-digital-art-through-immersive-experiences(1).jpg"
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
      {/* Mobile View */}
      <div className="flex lg:hidden flex-col justify-center items-center w-full h-screen relative">
        <div className="absolute inset-0 w-full h-full bg-black overflow-hidden">
          <img
            src="/images/beauty-digital-art-through-immersive-experiences (4).jpg"
            alt=""
            className="w-full h-full object-cover opacity-30"
          />
        </div>
        <div className="relative flex flex-col justify-center items-center text-center p-4">
          <h1 className="text-white text-2xl m-4 font-varela">
            Techmertia Pvt Ltd
          </h1>
          <h6 className="text-white text-lg mx-4 max-w-[80%] font-varela">
            Immersive AR/VR experiences redefining reality through innovative
            technology.
          </h6>
          <a href = "/arena" className="flex justify-center items-center w-[160px] p-3 font-varela h-[40px] text-sm font-bold m-4 text-[#352F44] bg-[#FAF0E6]  rounded-xl ">
            See Our Products
          </a>
        </div>
      </div>
    </div>
  )
}
