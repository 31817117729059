import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState(null);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name should not be empty.";
    } else if (formData.name.trim().split(" ").length > 6) {
      newErrors.name = "Name should not be more than 6 words.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email should not be empty.";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = "Invalid email format.";
      }
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone number should be exactly 10 digits.";
    }

    if (formData.message.trim().split(" ").length > 500) {
      newErrors.message = "Message should not be more than 500 words.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          formData,
          process.env.REACT_APP_EMAILJS_USER_ID
        )
        .then(
          (result) => {
            setNotification({
              type: "success",
              message: "Message sent successfully!",
            });
            setFormData({ name: "", email: "", phone: "", message: "" });
          },
          (error) => {
            setNotification({
              type: "error",
              message: "Failed to send the message. Please try again later.",
            });
          }
        );
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const closeNotification = () => {
    setNotification(null);
  };

  React.useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-[#333436] via-[#28292a] to-[#272829] text-white flex items-center justify-center p-4">
      {/* Notification */}
      {notification && (
        <div
          className={`absolute top-4 right-4 px-4 py-2 rounded-lg shadow-md text-white flex items-center justify-between gap-4 ${
            notification.type === "success" ? "bg-green-500" : "bg-red-500"
          }`}
        >
          <span>{notification.message}</span>
          <button onClick={closeNotification} className="text-white font-bold">
            &#10005;
          </button>
        </div>
      )}

      <div className="max-w-6xl w-full bg-[#353638] rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row">
        {/* Left Section with Context */}
        <div className="w-full md:w-1/2 p-8 flex flex-col justify-center bg-[#28292a] text-center relative">
          <h2 className="text-3xl font-bold mb-4 text-white">We Value Your Thoughts</h2>
          <p className="text-gray-400 mb-6">
            At our company, your ideas and feedback are the cornerstone of our innovation. 
            Share your thoughts and let's create something extraordinary together.
          </p>
          <div className="flex justify-center gap-6">
            <div className="text-gray-500 hover:text-white transition-opacity">
              <i className="fas fa-comments fa-3x opacity-50"></i>
              <p className="mt-2 text-sm">Share Ideas</p>
            </div>
            <div className="text-gray-500 hover:text-white transition-opacity">
              <i className="fas fa-handshake fa-3x opacity-50"></i>
              <p className="mt-2 text-sm">Collaborate</p>
            </div>
            <div className="text-gray-500 hover:text-white transition-opacity">
              <i className="fas fa-lightbulb fa-3x opacity-50"></i>
              <p className="mt-2 text-sm">Innovate</p>
            </div>
          </div>
          {/* Decorative Icons */}
          <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center space-x-8 z-0">
            <i
              className="fas fa-comments text-gray-500 opacity-30 transform rotate-12"
              style={{ fontSize: "6rem" }}
            ></i>
            <i
              className="fas fa-lightbulb text-gray-500 opacity-30 transform rotate-45"
              style={{ fontSize: "7rem" }}
            ></i>
            <i
              className="fas fa-handshake text-gray-500 opacity-30 transform rotate-90"
              style={{ fontSize: "5rem" }}
            ></i>
            <i
              className="fas fa-comment-alt text-gray-500 opacity-30 transform rotate-180"
              style={{ fontSize: "6rem" }}
            ></i>
          </div>
        </div>

        {/* Right Section with Contact Form */}
        <div className="w-full md:w-1/2 p-8">
          <h2 className="text-3xl font-bold mb-6">Contact Us</h2>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-sm font-medium mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg bg-[#272829] text-white border ${
                  errors.name ? "border-red-600" : "border-gray-600"
                } focus:ring-2 focus:ring-gray-400 focus:outline-none`}
                placeholder="Enter your name"
              />
              {errors.name && (
                <p className="text-red-400 text-sm">{errors.name}</p>
              )}
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg bg-[#272829] text-white border ${
                  errors.email ? "border-red-600" : "border-gray-600"
                } focus:ring-2 focus:ring-gray-400 focus:outline-none`}
                placeholder="Enter your email"
              />
              {errors.email && (
                <p className="text-red-400 text-sm">{errors.email}</p>
              )}
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium mb-1">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg bg-[#272829] text-white border ${
                  errors.phone ? "border-red-600" : "border-gray-600"
                } focus:ring-2 focus:ring-gray-400 focus:outline-none`}
                placeholder="Enter your phone number"
              />
              {errors.phone && (
                <p className="text-red-400 text-sm">{errors.phone}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium mb-1"
              >
                Message
              </label>
              <textarea
                id="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg bg-[#272829] text-white border ${
                  errors.message ? "border-red-600" : "border-gray-600"
                } focus:ring-2 focus:ring-gray-400 focus:outline-none`}
                placeholder="Enter your message"
              ></textarea>
              {errors.message && (
                <p className="text-red-400 text-sm">{errors.message}</p>
              )}
            </div>

            <button
              type="submit"
              className="w-full bg-[#181819] hover:bg-[#000000] text-white font-semibold py-2 rounded-lg shadow-md focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-800 focus:outline-none"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
