import React, { useState, useRef, useEffect } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import RotatingIcon from "./RotatingIcon";
import { Link } from "react-router-dom";
import LogoCanvas from "./LogoCanvas";

export default function Navbar01() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const dropdownRefs = useRef({});
  const [rotatedStates, setRotatedStates] = useState({});
  const [isSticky, setIsSticky] = useState(false);

  const mainNavHeaders = [
    { text: "What is Techmertia", href: "/" },
    { text: "What we Craft", href: "/arena" },
    { text: "Our Craftsmanship", href: "/craftsmanship" },
    { text: "Careers", href: "/career" },
  ];

  const sanitizeDropdownName = (name) => {
    if (typeof name !== "string") {
      console.error("sanitizeDropdownName: Expected a string but got:", name);
      return ""; // Return an empty string or a default value as needed
    }
    return name
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  const WhatIsTechmertia = [
    { text: "Who we Are ?", href: "/#who-we-are" },
    { text: "What we Do !", href: "/#what-we-do" },
    { text: "Our Mission", href: "/#mission" },
    { text: "Industrial Collaboration", href: "/#industrial-collaboration" },
  ];

  const WhatWeCraft = [
    { text: "3D Arena", href: "/arena#three-d-arena" },
    { text: "2D Arena", href: "/arena#two-d-arena" },
    { text: "AI Arena", href: "/arena#ai-arena" },
  ];

  const Craftsmanship = [
    { text: "3D Arena", href: "/craftsmanship#three-d-arena-games" },
    { text: "Our Partners", href: "/craftsmanship#our-partners" },
  ];


  const Careers = [
    { text: "About Us", href: "/career#about-our-company" },
    {
      title: "Career Opportunity",
      links: [
        {
          text: "Available Opportunity",
          href: "/career#available-opportunity",
        },
        { text: "Future Opportunity", href: "/career#future-opportunity" },
      ],
    },
  ];

  const toggleRotation = (id) => {
    setRotatedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the specific icon's rotation state
    }));
  };

  const toggleDropdown = (menu) => {
    setActiveDropdown((prev) => (prev === menu ? null : menu));
  };

  const cancelDropdown = () => {
    setActiveDropdown(null);
    setRotatedStates({});
  };

  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position
      if (window.scrollY > 350) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (activeDropdown) {
      const element = dropdownRefs.current[activeDropdown];
      if (element) {
        const rect = element.getBoundingClientRect();
        const sanitizedClassName = sanitizeDropdownName(activeDropdown);
        const dropdown = document.querySelector(
          `.dropdown-${sanitizedClassName}`
        );

        if (dropdown) {
          const dropdownRect = dropdown.getBoundingClientRect();

          let top = rect.bottom + window.scrollY; // Adjust for page scroll
          let left = rect.left + window.scrollX; // Added window.scrollX for horizontal alignment

          // Adjust position if dropdown goes beyond the viewport on the right
          if (left + dropdownRect.width > window.innerWidth) {
            left = window.innerWidth - dropdownRect.width - 16; // 16px for padding
          }

          // Adjust position if dropdown goes beyond the viewport on the bottom
          if (top + dropdownRect.height > window.innerHeight + window.scrollY) {
            top = rect.top + window.scrollY - dropdownRect.height - 8; // 8px for padding
          }

          setDropdownPosition({ top, left });
        }
      }
    }
  }, [activeDropdown]); // Run only when `activeDropdown` changes

  return (
    <>
      <div className={`hidden lg:flex flex-row justify-between  items-center z-[1000] w-full h-[70px] p-2  bg-[#272829] backdrop-blur-md 
      ${isSticky ? "sticky top-0 animate-slideDown" : "relative"}`}>
        <LogoCanvas />
        <div className="flex flex-row justify-center items-center">
          {mainNavHeaders.map((header, index) => (
            <div
              key={index}
              className="relative flex flex-row mx-2 items-center"
            >
              <Link
                to={header.href}
                ref={(el) => (dropdownRefs.current[header.text] = el)} // Use header.text instead of header
                className="text-[#B9B4C7] text-lg mx-2 font-varela"
              >
                {header.text}
              </Link>
              <button
                onClick={() => toggleDropdown(header.text)}
                className="flex justify-center items-center text-[#B9B4C7] text-xl"
              >
                <RotatingIcon
                  id={`icon${index}`} // Unique ID based on index or header
                  rotated={rotatedStates[`icon${index}`]} // Pass the state for the icon
                  toggleRotation={() => toggleRotation(`icon${index}`)} // Toggle state for the icon
                />
              </button>
            </div>
          ))}
        </div>
      </div>
      {activeDropdown && (
        <div
          className={` z-[9999] fixed mt-2 bg-[#272829] text-[#B9B4C7] w-48 rounded shadow-lg dropdown-${sanitizeDropdownName(
            activeDropdown
          )}`}
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          {activeDropdown === "What is Techmertia" && (
            <>
              {WhatIsTechmertia.map((item, index) => (
                <Link
                  key={index}
                  to={item.href}
                  className="block px-4 py-2 hover:border-b-2 rounded-xl hover:border-white duration-75 animate-slideDown font-varela"
                  onClick={cancelDropdown}
                >
                  {item.text}
                </Link>
              ))}
            </>
          )}
          {activeDropdown === "What we Craft" && (
            <>
              {WhatWeCraft.map((item, index) => (
                <Link
                  to={item.href}
                  key={index}
                  className="block px-2 py-2 hover:border-b-2 rounded-xl hover:border-white duration-75 font-varela animate-slideDown"
                  onClick={cancelDropdown}
                >
                  {item.text}
                </Link>
              ))}
            </>
          )}
          {activeDropdown === "Our Craftsmanship" && (
            <>
              {Craftsmanship.map((item, index) => (
                <Link
                  to={item.href}
                  key={index}
                  className="block px-2 py-2 hover:border-b-2 rounded-xl hover:border-white duration-75 font-varela animate-slideDown"
                  onClick={cancelDropdown}
                >
                  {item.text}
                </Link>
              ))}
            </>
          )}
          {activeDropdown === "Careers" && (
            <>
              {Careers.map((item, index) => (
                <div key={index} className="mt-2">
                  {item.title ? (
                    <>
                      <h3 className="px-2 py-1 text-lg font-bold">
                        {item.title}
                      </h3>
                      {item.links.map((link, i) => (
                        <Link
                          key={i}
                          to={link.href}
                          className="block px-2 py-2 hover:border-b-2 rounded-xl hover:border-white duration-75 font-varela animate-slideDown"
                          onClick={cancelDropdown}
                        >
                          {link.text}
                        </Link>
                      ))}
                    </>
                  ) : (
                    <Link
                      to={item.href}
                      className="block px-2 py-2 hover:border-b-2 rounded-xl hover:border-white duration-75 font-varela animate-slideDown"
                      onClick={cancelDropdown}
                    >
                      {item.text}
                    </Link>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
}
