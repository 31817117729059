import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ThreeDArenaGames from "../components/ThreeDArenaGames";
import OurPartners from "../components/OurPartners";

export default function ArenaPage() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <div id="three-d-arena-games">
        <ThreeDArenaGames />
      </div>

      <div id="our-partners">
        <OurPartners />
      </div>
    </>
  );
}
