import React, { useState } from "react";
import { FaGamepad, FaVideo, FaImages } from "react-icons/fa";

export default function ThreeDArenaGames() {
  const _3DArenaGames = [
    {
      id: 1,
      title: "Employee Management System",
      img: ["/images/ems/1.png","/images/ems/2.png","/images/ems/3.png" , "/images/ems/4.png"],
      desc: "The Employee Management System (EMS) is a comprehensive platform designed to streamline the management of employee data and operations. It allows administrators to track employee attendance, manage tasks, monitor performance, and oversee project progress. The system also offers features for managing leaves, payroll, and employee profiles. With real-time reporting, task allocation, and performance tracking, EMS helps organizations enhance productivity, ensure smooth workflow management, and maintain detailed records for each employee. This intuitive system is essential for businesses looking to improve their human resource management and foster efficient team collaboration.",
    },
    {
      id: 2,
      title: "Holiday",
      img: ["/images/holiday/1.png","/images/holiday/2.png","/images/holiday/3.png"],
      desc: "Holiday is a user-friendly app that simplifies the process of booking accommodations for your next getaway. Whether you're looking for a luxurious hotel, a cozy house, or a peaceful village retreat, Holiday offers a wide range of options to suit all preferences and budgets. The app provides seamless booking experiences with detailed listings, photos, and customer reviews to help users make informed decisions. With an intuitive interface, real-time availability, and easy payment options, Holiday makes planning and booking your next vacation hassle-free. Perfect for travelers seeking diverse and customizable lodging experiences.",
    },
    {
      id: 3,
      title: "TaskMate",
      video: "/images/vrOffice/1st.mp4",
      img: ["/images/taskMate/Screenshot 2024-12-14 193854 1.png","/images/taskMate/Screenshot 2024-12-14 193836 1.png","/images/taskMate/Screenshot 2024-12-14 193810 1.png" , "/images/taskMate/Screenshot 2024-12-14 193633 1.png" , "/images/taskMate/Screenshot 2024-12-14 193722 1.png", "/images/taskMate/Screenshot 2024-12-14 193755 1.png", "/images/taskMate/Screenshot 2024-12-14 193711 1.png", "/images/taskMate/Screenshot 2024-12-14 193658 1.png"],
      desc: "The VR Office is a cutting-edge virtual workspace available on PC, VR, and Android devices, ensuring seamless collaboration across platforms. It features 3D real avatars for lifelike interactions, real-time chat, file sharing, live meetings, and advanced administrative tools for tracking employee data like PC screens, attendance, tasks, and projects. This platform combines immersive technology with productivity tools, redefining modern remote workspaces.",
    },
    {
      id: 4,
      title: "ShopSphere",
      img: ["/images/e-commerce/1.png","/images/e-commerce/2.png","/images/e-commerce/3.png" , "/images/e-commerce/4.png" , "/images/e-commerce/5.png", "/images/e-commerce/6.png", "/images/e-commerce/7.png", "/images/e-commerce/8.png"],
      desc: "ShopSphere is a dynamic e-commerce platform designed to offer a seamless and personalized shopping experience. Whether you're browsing for fashion, electronics, home goods, or more, ShopSphere brings a vast range of products right to your fingertips. With an intuitive interface, secure payment options, and fast delivery services, it ensures a smooth shopping journey from start to finish. Featuring user-friendly filters, wishlists, and personalized recommendations, ShopSphere helps you find exactly what you need with ease. Enjoy an effortless shopping experience and discover unbeatable deals, all in one app.",
    },
    {
      id: 5,
      title: "Rider Brawl",
      video: "/images/ridersBrawl/IMG_9553.mp4",
      img: ["/images/ridersBrawl/Screenshot 2024-12-16 030634.png", "/images/ridersBrawl/Screenshot 2024-12-16 030555.png", "/images/ridersBrawl/Screenshot 2024-12-16 030716.png", "/images/ridersBrawl/Screenshot 2024-12-16 030541.png"],
      desc: "Riders Brawl VR is an exhilarating bike racing game that supports PC, VR, and Android devices, offering cross-platform compatibility for single and multiplayer modes. Race through neon-lit cityscapes, dynamic race tracks, and stunning nightscapes with immersive visuals and lifelike mechanics. Whether solo or battling rivals online, Riders Brawl VR delivers the ultimate bike racing adventure for every platform.",
    },
    {
      id: 6,
      title: "F1 Race",
      video: "/images/f1Race/Untitled design.mp4",
      img: ["/images/f1Race/IMG_9569.JPG", "/images/f1Race/Screenshot 2024-12-16 030244.png", "/images/f1Race/Screenshot 2024-12-16 030300.png", "/images/f1Race/Screenshot 2024-12-16 030323.png"],
      desc: "The F1 VR Race is an adrenaline-fueled experience that lets players compete on PC, VR, and Android devices. Dive into immersive single-player modes or challenge others in multiplayer races across realistic tracks with precision controls and high-speed action. With cross-platform play, F1 VR Race brings the excitement of Formula 1 racing to everyone, delivering a seamless and thrilling experience.",
    },
    {
      id: 7,
      title: "VR Office",
      video: "/images/vrOffice/1st.mp4",
      img: [ "/images/vrOffice/Screenshot 2024-12-16 191522.png",  "/images/vrOffice/Screenshot 2024-12-16 191510.png ","/images/vrOffice/Screenshot 2024-12-16 191458.png", "/images/vrOffice/Screenshot 2024-12-16 191447.png" ,"/images/vrOffice/Screenshot 2024-12-16 025933.png", "/images/vrOffice/Screenshot 2024-12-16 030108.png", "/images/vrOffice/Screenshot 2024-12-16 030134.png"],
      desc: "The VR Office is a cutting-edge virtual workspace available on PC, VR, and Android devices, ensuring seamless collaboration across platforms. It features 3D real avatars for lifelike interactions, real-time chat, file sharing, live meetings, and advanced administrative tools for tracking employee data like PC screens, attendance, tasks, and projects. This platform combines immersive technology with productivity tools, redefining modern remote workspaces.",
    },
    
    
    {
      id: 8,
      title: "Chopper Simulation",
      video: "/images/hellicopter/Helicopter.mp4",
      img: ["/images/hellicopter/Screenshot 2024-12-16 030842.png", "/images/hellicopter/Screenshot 2024-12-16 030900.png", "/images/hellicopter/Screenshot 2024-12-16 030911.png", "/images/hellicopter/Screenshot 2024-12-16 030922.png"],
      desc: "Chopper Simulation VR provides an immersive helicopter piloting experience with cross-platform support for PC, VR, and Android devices. Players can engage in mission-based single-player campaigns or multiplayer challenges like rescue missions, cargo transport, and aerial races. With stunning visuals and realistic controls, Chopper Simulation VR ensures a seamless and exciting flight experience, no matter the platform.",
    },
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const openImageDialog = (image) => {
    setSelectedImage(image);
  };

  const closeImageDialog = () => {
    setSelectedImage(null);
  };

  return (
    <section className="flex flex-col w-full p-6 bg-gradient-to-b from-[#333436] via-[#28292a] to-[#272829]">
      <h6 className="text-white w-full p-3 animate-slideInBlurredTop text-2xl lg:text-6xl font-varela">
        3D Arena
      </h6>
      <div className="flex w-full h-auto p-6 animate-slideInBlurredTop1 ">
        <p className="text-lg md:text-2xl font-varela text-white">
          At Techmertia, we push the boundaries of immersive entertainment with
          our cutting-edge AR, MR, and VR game experiences. Our innovative 3D
          games transport players into realistic and interactive worlds,
          offering unprecedented levels of engagement and excitement. From
          action-packed adventures to mind-bending puzzles, we create games that
          captivate players with their stunning visuals and dynamic gameplay.
          With a focus on pushing the limits of immersive technology, our games
          offer not just entertainment but a fully interactive experience that
          transports users to new, virtual realms. Whether you're exploring new
          worlds or solving complex challenges, Techmertia’s AR/VR games provide
          a next-level experience that keeps players coming back for more.
        </p>
      </div>
      <div className="flex flex-col w-full h-auto">
  <h2 className="text-white w-full p-3 text-[30px] font-bold font-varela">
    Projects
  </h2>
  <div className="flex justify-center w-full h-auto">
    <div className="flex flex-col w-[95%] p-4 h-auto bg-[#3c3b3f] rounded-xl">
      {_3DArenaGames.map((game) => {
        return (
          <div
            id={game.id}
            className="flex flex-col w-full h-auto m-6 p-8 bg-gradient-to-br from-[#1e1e22] to-[#2f2e33] rounded-xl shadow-2xl border border-[#48474b] mx-auto"
            key={game.id}
          >
            <div className="flex flex-col items-center mb-6">
              <div className="flex items-center justify-center bg-[#3a393e] p-4 rounded-full mb-4">
                <FaGamepad className="text-[#f39c12] text-5xl" />
              </div>
              <h1 className="text-4xl font-bold text-white tracking-wide">
                {game.title}
              </h1>
              <p className="text-gray-400 text-lg mt-2 text-center">
                Discover the exciting features and immersive experience of{" "}
                {game.title}.
              </p>
            </div>

            <div className="bg-[#3a393e] p-6 rounded-lg shadow-md mb-8">
              <p className="text-gray-300 text-justify leading-normal md:leading-relaxed">
                {game.desc}
              </p>
            </div>

            <div className="mb-8">
              <div className="flex items-center justify-start mb-4">
                <FaImages className="text-[#f39c12] text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-white">Gallery</h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {game.img.map((images, index) => {
                  return (
                    <img
                      key={index}
                      className="rounded-lg object-cover w-full h-[150px] border border-gray-700 shadow-md hover:scale-110 transition-transform cursor-pointer"
                      src={images}
                      alt={`image-${index}`}
                      onClick={() => openImageDialog(images)}
                    />
                  );
                })}
              </div>
            </div>

            {/* Only render video if the id is greater than 3 */}
            {game.id > 5 && game.video && (
              <div>
                <div className="flex items-center justify-start mb-4">
                  <FaVideo className="text-[#f39c12] text-3xl mr-3" />
                  <h2 className="text-2xl font-semibold text-white">Watch Video</h2>
                </div>
                <div className="flex justify-center">
                  <video
                    controls
                    muted
                    type="video/mp4"
                    className="rounded-lg object-cover w-full max-w-4xl border border-gray-700 shadow-md hover:shadow-lg transition-shadow"
                    src={game.video}
                  ></video>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  </div>
</div>


      {/* Modal for displaying selected image */}
      {selectedImage && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div
            className="relative bg-[#1e1e22] p-4 rounded-lg shadow-lg"
            style={{
              width: "70%",
              height: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="absolute top-2 right-2 text-white text-2xl font-bold focus:outline-none"
              onClick={closeImageDialog}
            >
              &times;
            </button>
            <img
              src={selectedImage}
              alt="Selected"
              className="max-w-full max-h-full rounded-lg"
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>
      )}

      <div className="flex w-full h-auto p-6">
        <p className="text-lg md:text-2xl font-varela text-white">
          At Techmertia, we are dedicated to pushing the boundaries of
          what&#39;s possible with AR, VR, and MR technologies. Our solutions
          are designed to enhance user experiences, streamline processes, and
          drive innovation across various sectors. Discover how we can help you
          transform your ideas into immersive realities—reach out to us at
          <strong> contact@techmertia.com</strong> to explore our services and
          start your journey into the future of technology.
        </p>
      </div>
    </section>
  );
}
