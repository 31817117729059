import React from "react";

export default function OurPartners() {
  const partners = [
    { name: "Puminati Digital Pvt Ltd", logo: "images/partners/Screenshot 2024-12-16 at 05-32-54 Best Digital Marketing Company in Haldwani Uttarakhand Puminati Digital.png" },
    { name: "Edverse Technology", logo: "images/partners/result.png" },
    { name: "Khanday Solutions", logo: "images/partners/Logo for Khanday Solutions.jpg" },
    { name: "Nexus Dispatch Pvt Ltd", logo: "images/partners/logo of Nexus Dispatch Pvt Ltd.jpg" },
    { name: "Chenault Logistics", logo: "images/partners/Chenault.png" },
    { name: "Xertz Studios", logo: "images/partners/logo of Xertz Studios.jpg" },
    { name: "Imperial Hotels Group", logo: "images/partners/logo of Imperial Hotels Group.jpg" },
    { name: "Dawa Limited", logo: "images/partners/logo of Dawa Limited.jpg" },
  ];

  return (
    <section className="flex flex-col w-full p-8 bg-gradient-to-b from-[#272829] via-[#28292a] to-[#333436]">
      <h6 className="text-white w-full p-3 animate-slideInBlurredTop text-2xl lg:text-6xl font-varela">
        Our Partners
      </h6>
      <div className="flex w-full h-auto p-6 animate-slideInBlurredTop1">
        <p className="text-lg md:text-2xl font-varela text-white">
          We are proud to collaborate with some of the most innovative and industry-leading companies. Our partners share our vision of excellence and innovation, driving success through mutual cooperation. Together, we strive to create impactful solutions and deliver exceptional value to our clients and communities.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 w-full p-6">
        {partners.map((partner, index) => (
          <div
            key={index}
            className="group flex flex-col items-center bg-[#1e1e22] rounded-2xl p-6 shadow-lg transition-transform duration-300 hover:scale-105 hover:rotate-2 hover:shadow-2xl hover:bg-[#2c2f30] transform-gpu"
          >
            <img
              src={partner.logo}
              alt={`${partner.name} logo`}
              className="w-32 h-32 object-contain mb-4 rounded-full border-4 border-gray-700 transition-all duration-500 transform group-hover:scale-110"
            />
            <h3 className="text-white text-xl lg:text-2xl font-semibold mt-4 group-hover:text-teal-400 transition-colors duration-300 tracking-wide">
              {partner.name}
            </h3>
          </div>
        ))}
      </div>
    </section>
  );
}
